import React from 'react';

import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { EquipmentTypeEuropean } from 'shared/enums/equipment-type-european.enum';
import { Translation } from 'shared/components/translation/translation.component';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';

export const getEquipmentTypeList = (isEuropean, equipmentCode: string): JSX.Element[]|JSX.Element => {
  if (isEuropean) {
    // i.e. ['F', 'L', 'PO', 'B', 'D', 'V', 'R', 'Z', 'I', 'D']
    const codes = Object.keys(EquipmentTypeEuropean).filter(key => key.length < 3);

    // i.e. /(F|L|PO|B|V|R|D|Z|I)/g
    const regex = new RegExp(`(${codes.join('|')})`, 'g');

    // find all type matches from that regexp, and turn those into strings
    const matches = (equipmentCode || '').match(regex); // || [];

    if (matches?.length) {
      return matches.map((code, index) =>
        <React.Fragment key={index}>
          <Translation resource={EquipmentTypeEuropean[code] as any}/>
          {index < (matches.length - 1) && ', '}
        </React.Fragment>
      );
    }
  } else {
    // i.e. ['F', 'L', 'PO', 'B', 'D', 'V', 'R', 'Z', 'I', 'D']
    const codes = Object.keys(EquipmentType).filter(key => key.length < 3);

    // i.e. /(F|L|PO|B|V|R|D|Z|I)/g
    const regex = new RegExp(`(${codes.join('|')})`, 'g');

    // find all type matches from that regexp, and turn those into strings
    const matches = (equipmentCode || '').match(regex); // || [];

    if (matches?.length) {
      return matches.map((code, index) =>
        <React.Fragment key={index}>
          <Translation resource={EquipmentType[code] as any}/>
          {index < (matches.length - 1) && ', '}
        </React.Fragment>
      );
    }
  }

  return <React.Fragment>{equipmentCode}</React.Fragment>;
};

export const getEquipmentTypeListString = (isEuropean: boolean, equipmentCode: string, resources: Resources): string => {
  if (isEuropean) {
    // i.e. ['F', 'L', 'PO', 'B', 'D', 'V', 'R', 'Z', 'I', 'D']
    const codes = Object.keys(EquipmentTypeEuropean).filter(key => key.length < 3);

    // i.e. /(F|L|PO|B|V|R|D|Z|I)/g
    const regex = new RegExp(`(${codes.join('|')})`, 'g');

    // find all type matches from that regexp, and turn those into strings
    const matches = (equipmentCode || '').match(regex); // || [];

    if (matches?.length) {
      return matches.map((code) =>
        resources[EquipmentTypeEuropean[code]]
      ).join(',');
    }
  } else {
    // i.e. ['F', 'L', 'PO', 'B', 'D', 'V', 'R', 'Z', 'I', 'D']
    const codes = Object.keys(EquipmentType).filter(key => key.length < 3);

    // i.e. /(F|L|PO|B|V|R|D|Z|I)/g
    const regex = new RegExp(`(${codes.join('|')})`, 'g');

    // find all type matches from that regexp, and turn those into strings
    const matches = (equipmentCode || '').match(regex); // || [];

    if (matches?.length) {
      return matches.map((code) =>
        resources[EquipmentType[code]]
      ).join(',');
    }
  }

  return equipmentCode;
};

interface OwnProps {
  equipmentCode: string;
}

type Props = OwnProps;

export const EquipmentTypeList: React.FunctionComponent<Props> = ({equipmentCode}) => {
  const user = useStoredUser();
  return (
    <span>
    {getEquipmentTypeList(user.isEuropean(), equipmentCode)}
  </span>
  );
};
